<template>

  <b-row>
    <b-col cols="12">


      <b-card>
        <b-card-title>
          {{ $t('How to upload') }}
        </b-card-title>
        <b-card-text>
          <div>
            {{
              $t('Please prepare an excel (xls or xlsx) file using the following column and adding one element for each row')
            }}.
          </div>

          <spacer height="30"></spacer>
          <div>
            <b-table responsive :items="items" class="mb-0"/>
          </div>
          <spacer height="10"></spacer>

          <div align="right">
            <a href="https://api.basic.italia.ae/templates/template_import_attrezzature.xlsx" target="_blank">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  @disabled="disableAllButtons"
              >
                <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                />
                <span class="align-middle">{{ $t('Download the template') }}</span>
              </b-button>
            </a>
          </div>
        </b-card-text>
      </b-card>


      <b-card
          border-variant="primary"
      >

        <b-card-text>
          <div class="upload-file">
            <div>
              <div class="mb-8 h6">
                <import-excel :onSuccess="loadDataInTable"/>
              </div>

            </div>
          </div>
        </b-card-text>
      </b-card>


      <b-card ref="list" title="" action-refresh @refresh="refresh" v-if="downloadProcessed">


        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">


        </div>

        <!-- table -->
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
        enabled: true,
        externalQuery: searchTerm }"

            :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        >

          <!-- Slot: Table Column -->
          <template
              slot="table-column"
              slot-scope="props"
          >

        <span>
           {{ $t(props.column.label) }}
        </span>
          </template>

          <!-- Slot: Table Row -->
          <template
              slot="table-row"
              slot-scope="props"
          >
            <!-- Column: First Name -->
            <span
                v-if="props.column.field === 'manufacturer'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.manufacturer }}</span>
        </span>

            <!-- Column: Last Name -->
            <span
                v-else-if="props.column.field === 'model'"
                class="text-nowrap align-middle"
            >
                    <span>{{ props.row.model }}</span>

        </span>


            <!-- Column: Equipment Id -->
            <span
                v-else-if="props.column.field === 'equipment_id'"
                class="text-nowrap align-middle"
            >
                    <span>{{ props.row.equipment_id }}</span>

        </span>


            <!-- Column: Description-->
            <span
                v-else-if="props.column.field === 'description'"
                class="text-nowrap align-middle"
            >
                    <span>{{ props.row.description }}</span>

        </span>




          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >

            <div class="d-flex justify-content-between flex-wrap">


              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10','15']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{
                    $t('message.pageText2')
                  }} </span>
              </div>
              <div>

                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>

                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>

        <div align="right">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @disabled="disableAllButtons"
              @click="finalize_import"
          >

            <span class="align-middle">{{ $t('Complete the import') }}</span>
          </b-button>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>


<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import {BCardActions} from "@core/components/b-card-actions";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BAlert,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BFormCheckbox,
  BCard,
  BCardTitle,
  BCardText,
  BTable,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import {codeI18n} from './code'
import useJwt from '@/auth/jwt/useJwt'
import EquipmentService from "@/basic/equipmentService";
import Ripple from 'vue-ripple-directive'

import {BButton} from "bootstrap-vue";
import ExportExcel from "@/views/components/excel-export/ExportExcel";

import Vue from "vue";
import i18n from "@/libs/i18n";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ability from "@/libs/acl/ability";
import GoodTableBasic from './GoodTableBasic.vue'
import GoodTableRowGroup from './GoodTableRowGroup.vue'
import GoodTableColumnSearch from './GoodTableColumnSearch.vue'
import GoodTableAdvanceSearch from './GoodTableAdvanceSearch.vue'
import EquipmentsListComponent from "@/views/table/vue-good-table/EquipmentsListComponent";
import GoodTableSsr from './GoodTableSsr.vue'

const moment = require('moment');

Vue.use(ExportExcel);

export default {
  components: {
    BCardCode,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    GoodTableBasic,
    GoodTableRowGroup,
    GoodTableColumnSearch,
    GoodTableAdvanceSearch,
    EquipmentsListComponent,
    GoodTableSsr,
    BCard,
    BCardTitle,
    BTable,
    BCardText
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [
        {
          produttore: 'Makita',
          modello: 'Dhp453rfe',
          numero_di_serie: '021210001',
          descrizione: '',
        },
        {
          produttore: 'Arebos',
          modello: '4260199750179',
          numero_di_serie: '021210002',
          descrizione: 'Fresa elettrica',
        },

      ],
      pageLength: 15,
      codeI18n,
      downloadProcessed: false,
      dir: false,
      editDisabled: !ability.can("manage", "Basic HR"),
      disableAllButtons: false,
      terminated_label: "Mostra dismessi",
      columns: [
        {
          label: 'Manufacturer',
          field: 'manufacturer',
        },
        {
          label: 'Model',
          field: 'model',
        },

        {
          label: 'Equipment Id',
          field: 'equipment_id',
        },

        {
          label: 'Description',
          field: 'description',
        },
      ],

      /*

 manufacturer: `${_item['NOME']}`.trim(),
            model: `${_item['COGNOME']}`.trim(),
            model_id: `${_item['CF']}`.trim(),
            equipment_id: `${_item['ID']}`.trim(),
            birth_province: `${_item['PROVINCIA DI NASCITA']}`.trim(),
            birth_place: `${_item['LUOGO DI NASCITA']}`.trim(),
            birthday: `${_item['DATA DI NASCITA']}`.trim(),
            protected_category: `${_item['CATEGORIE PROTETTE']}`.trim(),
            phone: `${_item['TELEFONO']}`.trim(),
            mobile: `${_item['CELLULARE']}`.trim(),
            email: `${_item['EMAIL']}`.trim()
 */




      excel_columns: [
        {
          label: i18n.t('Manufacturer'),
          field: 'manufacturer',
        },
        {
          label: i18n.t('Model'),
          field: 'model',
        },
        {
          label: i18n.t('Equipment Id'),
          field: 'equipment_id',
        },

      ],
      rows: [],
      terminated_equipments: [],
      equipments: [],
      searchTerm: '',
      tableData: [],

    }
  },

  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {

    /**
     * Read excle file data and display in table format
     * @author Madhura Shah
     */
    loadDataInTable({results, header, meta}) {
      console.log(this.tableData);
      console.log(this.header);

      this.header = header;
      this.tableData = this.selected = results;
      this.sheetName = meta.sheetName;
      let selected = this.tableData.map(_item =>
          ({
            manufacturer: `${_item['PRODUTTORE']}`.trim(),
            model: `${_item['MODELLO']}`.trim(),
            equipment_id: (`${_item['NUMERO DI SERIE']}`!=='undefined')?`${_item['NUMERO DI SERIE']}`.trim():"",
            description: (`${_item['DESCRIZIONE']}`!=='undefined')?`${_item['DESCRIZIONE']}`.trim():"",
          })
      );



      console.log('tabledata', this.tableData);
      console.log('selected', selected);
      this.rows = selected
      this.downloadProcessed = true

      console.log('header', this.header);

    },


    refresh() {
      console.log("refresh")
      //this.$refs["list"].showLoading = true


      let company_id = store.state.userCompany.id;
      const equipmentService = new EmployeeService(useJwt)

      equipmentService.equipments(company_id, {}).then(response => {
        this.equipments = response.data.data.equipments
        this.terminated_equipments = response.data.data.terminated_equipments

        if (this.show_terminated) {
          this.rows = this.terminated_equipments

        } else {
          this.rows = this.equipments
        }
        console.log('rows', this.rows)
        this.$refs["list"].showLoading = false

      })

    },


    finalize_import() {
      let company_id = store.state.userCompany.id;
      const equipmentService = new EquipmentService(useJwt)


      const data = {
        company_id: company_id,
        equipments: this.rows,
      };
      this.disableAllButtons = true;

      equipmentService.import_equipments(data).then(response => {
        console.log("response", response)


        if (response.data.status === 'success') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('Equipments imported'),
              icon: 'AlertIcon',
              variant: 'success',
              text: i18n.t('Equipments added with success'),
            },
          })
          this.$router.push({name: 'equipments'})

        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('Equipments not imported'),
              icon: 'AlertIcon',
              variant: 'danger',
              text: i18n.t(response.data.message),
            },
          })
          this.disableAllButtons = false;
        }
      })
    },
  },
  mounted() {

    this.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'selected_company') {
        console.log(`Updating selected_company to ${state.userCompany.id}`);
        this.refresh();
      }
    });


    let company_id = store.state.userCompany.id;

    // this.refresh();
    //this.$http.get('/good-table/basic')
    //.then(res => { this.rows = res.data })
  },
  beforeDestroy() {
    this.unsubscribe();
  }
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
